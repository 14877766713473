import React, {forwardRef, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import addDays from 'date-fns/addDays';
import enGB from 'date-fns/locale/en-GB';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';

import '../styles/Search.scss';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import {propertiesGet} from '../actions/Property';
import {useDispatch, useSelector} from 'react-redux';
import {getProperties, isFetchingProperties} from '../selectors/Property';
import SelectInput from '../components/SelectInput';
import {ratesGet} from '../actions/Rate';
import {didFetchingRatesFailed, isFetchingRates} from '../selectors/Rate';
import CONSTANTS from '../helpers/constants';
import calendarSvg from '../assets/calendar.svg';
import {
  bookingPropertyClear,
  bookingPropertySet,
  bookingSearchClear,
} from '../actions/Booking';

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

const Search = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bookingPropertyClear());
    dispatch(bookingSearchClear());
    dispatch(propertiesGet());
  }, [dispatch]);
  const history = useHistory();
  const dateTo = addDays(new Date(Date.now()), 3);
  const [from, setFrom] = useState(new Date(Date.now()));
  const [to, setTo] = useState(dateTo);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [promo, setPromo] = useState('');
  const [propertyCode, setPropertyCode] = useState('');

  const roomOpts = _.range(1, 6).map((opt) => ({label: opt, value: opt}));

  const adultOpts = _.range(1, 10).map((opt) => ({label: opt, value: opt}));

  const childrenOpts = _.range(0, 10).map((opt) => ({label: opt, value: opt}));

  const fetchingProperties = useSelector(isFetchingProperties);

  const fetchingRates = useSelector(isFetchingRates);

  const fetchingRatesFailed = useSelector(didFetchingRatesFailed);

  const properties = useSelector(getProperties);

  // const propOptions =
  //   properties?.map((prop) => ({label: prop.name, value: prop.propertyCode})) ||
  //   [];

  const propOptions = [
    {label: 'EARTH ALTITUDE', value: 'EARTH ALTITUDE'},
    {label: 'EARTH MARJAN ISLAND', value: 'EARTH MARJAN ISLAND'},
  ];

  const onSearchSubmit = async () => {
    await dispatch(
      ratesGet({
        from,
        to,
        adults,
        children,
        promo,
        roomsQuantity: rooms,
        propertyCode,
      }),
    );
    if (!fetchingRatesFailed) {
      if (propertyCode) {
        const activeProperty = properties.find(
          (prop) => prop.propertyCode === propertyCode,
        );
        if (activeProperty) {
          dispatch(bookingPropertySet(activeProperty));
          history.push(CONSTANTS.APP_ROUTES.ROOMS);
        }
      } else {
        history.push(CONSTANTS.APP_ROUTES.PROPERTIES);
      }
    }
  };

  const onChange = (field: string, val: string) => {
    switch (field) {
      case 'adults':
        setAdults(parseInt(val, 10));
        break;
      case 'children':
        setChildren(parseInt(val, 10));
        break;
      case 'rooms':
        setRooms(parseInt(val, 10));
        break;
      case 'promo':
        setPromo(val);
        break;
      case 'property':
        setPropertyCode(val);
        break;
    }
  };

  // @ts-ignore
  const DatePickerCustomInput = forwardRef(({value, onClick}, ref) => (
    <div
      className="ibe-datepicker-custom-input"
      onClick={onClick}
      // @ts-ignore
      ref={ref}>
      <input disabled={true} value={value} type="text" />
      <img src={calendarSvg} alt="calendar-icon" />
    </div>
  ));

  return (
    <div className="search">
      <div className="search__header">
        <h1>WHERE ON EARTH</h1>
        <h2>ARE YOU GOING?</h2>
        <div className="search__header__red-hr" />
      </div>
      {fetchingProperties && (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!fetchingProperties && (
        <div className="search__body mt-md-5 mt-2">
          <div className="row mt-md-3">
            <div className="col-md-6">
              <label>Property</label>
              <SelectInput
                options={propOptions}
                id="propertiesDropDown"
                value={propertyCode}
                onChange={(e) => onChange('property', e)}
                placeholder="Select Property"
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <label>Promo Code</label>
              <TextInput
                placeholder="Promo Code"
                value={promo}
                onChange={(e) => onChange('promo', e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Check-In</label>
              <div className="ibe-datepicker-input">
                <DatePicker
                  selected={from}
                  // @ts-ignore
                  onChange={(date: Date) => setFrom(date)}
                  selectsStart
                  startDate={from}
                  endDate={to}
                  dateFormat="P"
                  customInput={<DatePickerCustomInput />}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Check-Out</label>
              <div className="ibe-datepicker-input">
                <DatePicker
                  selected={to}
                  showPopperArrow
                  // @ts-ignore
                  onChange={(date) => setTo(date)}
                  selectsEnd
                  startDate={from}
                  endDate={to}
                  minDate={from}
                  dateFormat="P"
                  customInput={<DatePickerCustomInput />}
                />
              </div>
            </div>
          </div>
          <div className="row mt-md-3">
            <div className="col-md-4">
              <label>Number Of Adults</label>
              <SelectInput
                options={adultOpts}
                id="numberOfAdultsDropDown"
                value={adults}
                onChange={(e) => onChange('adults', e)}
                placeholder="Number of Adults"
              />
            </div>
            <div className="col-md-4">
              <label>Number Of Children</label>
              <SelectInput
                options={childrenOpts}
                id="numberOfChildrenDropDown"
                value={children}
                onChange={(e) => onChange('children', e)}
                placeholder="Number of Children"
              />
            </div>
            <div className="col-md-4">
              <label>Number Of Rooms</label>
              <SelectInput
                options={roomOpts}
                id="numberOfRoomsDropDown"
                value={rooms}
                onChange={(e) => onChange('rooms', e)}
                placeholder="Number of Rooms"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <Button
                loading={fetchingRates}
                type="cta"
                disabled
                text="SEARCH UNAVAILABLE"
                onClick={onSearchSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
