import React from 'react';
import {Dropdown} from 'bootstrap';
import {useState, useEffect, useRef} from 'react';
import './SelectInput.scss';
import {Property} from '../../types/Property';
import {Room} from '../../types/Room';
import {Rate} from '../../types/Rate';
import downArrowSvg from '../../assets/downArrow.svg';

type SelectInputOption = {
  label: string | number;
  value: string | number | undefined | null | Rate | Room | Property;
};

type SelectInputProps = {
  placeholder?: string;
  type?: 'grey' | 'blue';
  name?: string;
  id: string;
  disabled?: boolean;
  value?: string | number | undefined | null | Rate | Room | Property;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  options?: SelectInputOption[];
};

const SelectInput = (props: SelectInputProps) => {
  const {
    id = '',
    name = '',
    placeholder = '',
    type = 'grey',
    value = null,
    options = [],
    disabled = false,
    onChange = () => {},
    onBlur = () => {},
  } = props;

  const exampleDropDown = useRef();
  const [dropDown, setDropDown] = useState(null);

  useEffect(() => {
    //@ts-ignore
    setDropDown(new Dropdown(exampleDropDown.current));
  }, []);

  return (
    <div className="ibe-select-input">
      <div className="dropdown" aria-label={name} onBlur={onBlur}>
        <button
          className={`btn dropdown-btn-container dropdown-btn-${type}`}
          type="button"
          //@ts-ignore
          onClick={() => dropDown?.show()}
          //@ts-ignore
          ref={exampleDropDown}
          id={id}
          data-bs-toggle="dropdown"
          disabled={disabled}
          aria-expanded="false">
          <span>
            {value !== '' && value !== null && value !== undefined
              ? value
              : placeholder}
          </span>
          <img src={downArrowSvg} alt="dropDownArrow" />
        </button>
        <ul className="dropdown-menu" aria-labelledby={id}>
          {options?.map((opt, i) => (
            <li
              onClick={() => onChange(opt.value)}
              className={value === opt.value ? 'active' : ''}
              key={`opt-lbl-${i}`}>
              {opt.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectInput;
